<template>
  <div class="detailsMind">
    <div class="detailsTop">
      <el-carousel class="detailsTop" indicator-position="outside">
        <el-carousel-item class="detailsTop" v-for="i in imgList" :key="i.id">
          <img :src="i.url" class="detailsTopImg" />
        </el-carousel-item>
      </el-carousel>
      <div class="detailsTopRow">
        <div class="detailsTopRowOne">￥30</div>
        <div class="detailsTopRowFoot">
          <div class="detailsTopRowFootBtn" @click="buyClick">立即购买</div>
          <div class="detailsTopRowFootBtn">加入购物车</div>
        </div>
      </div>
    </div>
    <div class="detailsTitle">
      <div
        class="detailsTitleItem"
        :class="titleId == item.name ? 'detailsTitleItemActive' : ''"
        v-for="item in titleList"
        :key="item.id"
        @click="titleClick(item)"
      >{{item.name}}</div>
    </div>
    <div class="detailsBody" v-if="titleId == '详情'"></div>
    <div class="detailsBody" v-else-if="titleId == '评价'">
        <div class="detailsBodyCard">
            <div class="detailsBodyCardRow">
                <div class="detailsBodyCardRowLeft">
                    <img src="https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111142521_userTou.png" 
                    class="detailsBodyCardRowImg">
                </div>
                <div class="detailsBodyCardRowRight">
                    <div>186*****152</div>
                    <div>宝贝收到了，带了两天才来评价的，整感觉还是很好的。</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgList: [
        {
          id: 0,
          url: "https://ahyzml-1309303322.cos.ap-shanghai.myqcloud.com/files/20230111135109_comm.png",
        },
      ],
      titleList: [
        { id: 0, name: "详情" },
        { id: 1, name: "评价" },
      ],
      titleId: '评价',
    };
  },
  created(){},
  methods:{
    titleClick(s){
      this.titleId = s.name
    },
    buyClick(){
      this.$router.push({path:'/ordeDdeta'})
    }
  }
};
</script>

<style>
.detailsMind {
  width: 100%;
  height: 100%;
  background-color: #f5f7f9;
}

.detailsTop {
  position: relative;
  width: 100%;
  height: 800px;
}

.detailsTopImg {
  width: 100%;
  height: 100%;
}

.detailsTopRow {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  width: 38%;
  height: 150px;
}

.detailsTopRowOne {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: #000000;
  font-size: 40px;
}

.detailsTopRowFoot {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
}

.detailsTopRowFootBtn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #2680eb;
  color: #1764fc;
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  margin-right: 40px;
}

.detailsTopRowFootBtn:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}

.detailsTitle {
  width: 100%;
  height: 88px;
  padding: 0px 15%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.detailsTitleItem {
  width: 80px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: #141414;
  font-size: 20px;
  border-bottom: 4px solid #f5f7f9;
}

.detailsTitleItem:hover{
    cursor: pointer;
}

.detailsTitleItemActive {
  border-bottom: 4px solid #1764fc;
}

.detailsBody{
    width: 100%;
    min-height: 200px;
  padding: 0px 15%;
  box-sizing: border-box;
}

.detailsBodyCard{
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 30px;
    box-sizing: border-box;
}

.detailsBodyCardRow{
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
}

.detailsBodyCardRowLeft{
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
}

.detailsBodyCardRowImg{
    width: 58px;
    height: 58px;
}

.detailsBodyCardRowRight{
    width: calc(100% - 60px - 80px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #141414;
    font-size: 16px;
}
</style>